import React from "react";
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from 'gatsby-link'
import Layout from "../components/Layout/layout"
import { Heading, Title, TwoColContainer, Col1Image, Col2Info } from '../components/Layout/pageParts';
import { Colours } from '../components/Layout/colours';

function encode(data) {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

const FormContainer = styled.div`        
  padding-top:15px;
  padding-right:15px;
  border-radius: 8px;	        
`;

const FormInput = styled.input`
  background: ${Colours.Overcast};
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box; 
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;     
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;

  :focus{
  background: ${Colours.Focus};
  }
`;

const FormTextArea = styled.textarea`
  background: ${Colours.Overcast};
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  min-height:120px;
  box-sizing: border-box; 
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;         
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;

  :focus{
      background: ${Colours.Focus};
  }
`;

const FormButton = styled.button`
  position: relative;
  display: block;
  padding: 19px 39px 18px 39px;
  color: #FFF;
  margin: 0 auto;
  background: ${Colours.Darkest};
  font-size: 18px;
  text-align: center;
  font-style: normal;
  width: 100%;
  border: 1px solid ${Colours.Focus};;
  border-width: 1px 1px 3px;
  margin-bottom: 10px;

  :hover:enabled{
      background: ${Colours.Dark};
  }

  :disabled {
  opacity: 0.2;
  }
`;

const ContactPage = () => {

  const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "bg3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

const [state, setState] = React.useState({})
    const [canSend, setCanSend] = React.useState(false);

    const handleChange = (e) => {

        var latestState = { ...state, [e.target.name]: e.target.value };
        setState(latestState);

        const validName = latestState['name'] !== undefined && latestState['name'] !== '';
        const validEmail = latestState['email'] !== undefined && latestState['email'] !== '';
        const validMessage = latestState['message'] !== undefined && latestState['message'] !== '';
        setCanSend(validName && validEmail && validMessage);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
    <Layout>
       <TwoColContainer>
            <Col1Image>
                <Img alt="profile photo" fluid={data.fileName.childImageSharp.fluid} style={{ width: "95%", height: "95%" }} imgStyle={{ objectPosition: 'left' }} />
            </Col1Image>
            <Col2Info>
                <Heading>CONTACT ME</Heading>
                <Title>If you have any questions about essential oils or would like a personal wellness consulation then get in touch!</Title>
                <FormContainer>
                    <form name="contact"
                        method="post"
                        action="/thanks/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}>
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <p hidden>
                            <label>
                                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                            </label>
                        </p>
                        <FormInput type="text" name="name" placeholder="Your Name *" onChange={handleChange} />
                        <FormInput type="email" name="email" placeholder="Your Email *" onChange={handleChange} />
                        <FormTextArea name="message" placeholder="Your Message" onChange={handleChange}></FormTextArea>
                        <FormButton disabled={!canSend} type="submit">Send Message</FormButton>
                    </form>
                </FormContainer>
            </Col2Info>
        </TwoColContainer>
    </Layout>
    
)
};

export default ContactPage;